import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-capital',
  templateUrl: './capital.component.html',
  styleUrls: ['./capital.component.css']
})
export class CapitalComponent implements OnInit {

  constructor() { }
  impactdata = [
    {impact_name:"Incubation Cash Support",impact_image:"../../assets/images/benifits/cash-icon.png",total_number:"INR 2.5 Lakhs(Virtual) / INR 5 Lakhs(Physical)) ",},
    {impact_name:"Proof of Concept Funding",impact_image:"../../assets/images/benifits/grants-icon.png",total_number:"Up to INR 50 Lakhs ",},
    {impact_name:"Prototyping Funding",impact_image:"../../assets/images/benifits/sineinvestment-icon.png",total_number:"Up to INR 1 Crore",},
    {impact_name:"Product Development & Commercialisation Funding",impact_image:"../../assets/images/benifits/investor-connect.png",total_number:"200 +",},
    {impact_name:"Scale Up Funding",impact_image:"../../assets/images/benifits/bank-icon.png",total_number:"10 +",},
    
  ];

  ngOnInit() {
  }

}
