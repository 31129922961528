import { Injectable, ViewChild} from '@angular/core';
import { HttpInterceptor ,
        HttpRequest,
        HttpEvent,
        HttpHandler ,
        HttpHeaders,
      //   HttpError
      //   Response,
        HttpResponse
       } from '@angular/common/http'
import { Observable } from 'rxjs/Rx';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';
import 'rxjs/add/operator/do';

@Injectable()

export class Interceptor implements HttpInterceptor {

   constructor( private router : Router ) {}

   intercept(req : HttpRequest<any>, next : HttpHandler) : Observable<HttpEvent<any>> {
       var url = environment.api_url;
    //    console.log(url)
       let request;
    if(req.method === "POST"){
        // console.log(req.method,req.url);
        request = req.clone({ url : req.url })
        return next.handle(request).do((event: HttpEvent<any>) => {}, (err :any) => {})
    }
    else{
        request = req.clone({ url : url + req.url })
       return next.handle(request).do((event: HttpEvent<any>) => {}, (err :any) => {})
    }
       
   }
}