import { NgModule } from "@angular/core";
import { filter, map, mergeMap } from 'rxjs/operators';
import { Routes, RouterModule } from "@angular/router";
import{AuthGuard} from "./guard/auth.guard"
import {
  HashLocationStrategy,
  LocationStrategy,
  PathLocationStrategy
} from "@angular/common";
import { HomeComponent } from "./home/home.component";
import { PortfolioComponent } from "./portfolio/portfolio.component";
import { AboutComponent } from "./about/about.component";
import { TeamComponent } from "./team/team.component";
import { BoardComponent } from "./board/board.component";
import { GalleryComponent } from "./gallery/gallery.component";
import { ProgramComponent } from "./program/program.component";
import { ResourcesComponent } from "./resources/resources.component";
import { ProgramPrayasComponent } from "./program-prayas/program-prayas.component";
import { ProgramEirComponent } from "./program-eir/program-eir.component";
import { ProgramAitComponent } from "./program-ait/program-ait.component";
import { ProgramPluginComponent } from "./program-plugin/program-plugin.component";
import { FaqComponent } from "./faq/faq.component";
import { SineincubComponent } from "./sineincub/sineincub.component";
import { BionestComponent } from "./bionest/bionest.component";
import { WelcomepageComponent } from "./welcomepage/welcomepage.component";
import { ContactComponent } from "./contact/contact.component";
import { NewsComponent } from "./news/news.component";
import { XfactorComponent } from "./xfactor/xfactor.component";
import { DiscComponent } from "./disc/disc.component";
import { WeeprogramComponent } from "./weeprogram/weeprogram.component";
import { MeityloginComponent } from "./meitylogin/meitylogin.component";
import { MediacoverageComponent } from "./mediacoverage/mediacoverage.component";
import { TendorComponent } from "./tendor/tendor.component";
import { TenderdetailsComponent } from "./tenderdetails/tenderdetails.component";
import { ArchivetendersComponent } from "./archivetenders/archivetenders.component";
import { CareerComponent } from "./career/career.component";
import { AdvisorsComponent } from "./advisors/advisors.component";
import { MentorsComponent } from './mentors/mentors.component';
import { FacilitiesComponent } from "./facilities/facilities.component";
import { WhatwedoComponent } from "./whatwedo/whatwedo.component";
import { SupportpartnersComponent } from "./supportpartners/supportpartners.component";
import { OtherpartnersComponent } from "./otherpartners/otherpartners.component";
import { FacilitesdetailsComponent } from "./facilitesdetails/facilitesdetails.component";
import { TestimonialComponent } from "./testimonial/testimonial.component";
import { GalleryearComponent } from "./galleryear/galleryear.component";
import { UserLoginComponent } from "./user-login/user-login.component";
import { UserLoginSuccessComponent } from "./user-login-success/user-login-success.component";
import { RegisterComponent } from "./register/register.component";
import { ConnectusComponent } from "./connectus/connectus.component";
import {ErrorComponent} from "./error/error.component"
import {BenifitsineComponent} from "./benifitsine/benifitsine.component"
import { PartnerOfferingComponent } from "./partner-offering/partner-offering.component";
import { InfrastuctureComponent } from "./infrastucture/infrastucture.component";
import { CapitalComponent } from "./capital/capital.component";
import { EcosystemComponent } from "./ecosystem/ecosystem.component";
import { InfraGalleryComponent } from "./infra-gallery/infra-gallery.component";
import { OfferingComponent } from "./offering/offering.component";

const routes: Routes = [
  // { path: "", component: WelcomepageComponent },
  { path: "", component: HomeComponent },
  { path: "home/about", component: HomeComponent, data: { scrollTo: "about" } },
  { path: "portfolio", component: PortfolioComponent },
  { path: "about", component: AboutComponent },
  { path: "team", component: TeamComponent },
  { path: "board", component: BoardComponent },
  { path: "gallery", component: GalleryearComponent },
  { path: "gallery/:year", component: GalleryComponent },
  { path: "program", component: ProgramComponent },
  { path: "resources", component: ResourcesComponent },
  { path: "prayasprog", component: ProgramPrayasComponent },
  { path: "eirpog", component: ProgramEirComponent },
  { path: "aitprog", component: ProgramAitComponent },
  { path: "pluginprog", component: ProgramPluginComponent },
  { path: "faq", component: FaqComponent },
  { path: "programmes", component: SineincubComponent },
  { path: "programmes/:type", component: SineincubComponent },
  { path: "bionest", component: BionestComponent },
  { path: "contact", component: ContactComponent },
  { path: "newsletter", component: NewsComponent },
  { path: "xfactor", component: XfactorComponent },
  { path: "disc", component: DiscComponent },
  { path: "events", component: WeeprogramComponent },
  { path: "events/:id", component: WeeprogramComponent },
  { path: "meitylogin", component: MeityloginComponent },
  { path: "mediacoverage", component: MediacoverageComponent },
  { path: "tender", component: TendorComponent },
  { path: "tenderdetails/:id", component: TenderdetailsComponent },
  { path: "archivetenders", component: ArchivetendersComponent },
  { path: "career", component: CareerComponent },
  { path: "advisors", component: AdvisorsComponent },
  { path: "mentors", component: MentorsComponent },
  { path: "facilities", component: FacilitiesComponent },
  // { path: "whatwedo", component: WhatwedoComponent },
  { path: "partners", component: SupportpartnersComponent },
  { path: "partners/:type", component: SupportpartnersComponent },
  { path: "otherpartners", component: OtherpartnersComponent },
  { path: "facilitiesdetails", component: FacilitesdetailsComponent },
  { path: "facilitiesdetails/:id", component: FacilitesdetailsComponent },
  { path: "testimonial", component:  TestimonialComponent},
  { path: "user-login", component:  UserLoginComponent},
  { path: "user-login-success", component:  UserLoginSuccessComponent,canActivate : [AuthGuard]},
  { path: "register", component: RegisterComponent},
  { path: "connectus", component:ConnectusComponent},
  { path: "benefits", component:BenifitsineComponent},
  { path:"partner-offering", component:PartnerOfferingComponent },
  { path:"infrastucture", component:InfrastuctureComponent },
  { path:"capital", component:CapitalComponent },
  { path:"ecosystem", component:EcosystemComponent },
  { path:"infragallery", component:InfraGalleryComponent },
  { path:"offering", component:OfferingComponent },
  
  
  // {path:"404-error",component:ErrorComponent},
  {path:"**",component:ErrorComponent}


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [{ provide: PathLocationStrategy }],
  // providers: [{provide: LocationStrategy, useClass: HashLocationStrategy},],
  exports: [RouterModule]
})
export class AppRoutingModule {}
