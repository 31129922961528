import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NguCarousel } from '@ngu/carousel';
import { THROW_IF_NOT_FOUND } from '@angular/core/src/di/injector';
// import { SlugifyPipe } from '.././slugify.pipe';

@Component({
  selector: 'app-facilitesdetails',
  templateUrl: './facilitesdetails.component.html',
  styleUrls: ['./facilitesdetails.component.css']
})
export class FacilitesdetailsComponent implements OnInit {
  public carouselbanner1: NguCarousel;
  imagesrc: any; 
  imagesrc1: any;
   showdata2: any = []; 
   facilityData: any = []; 
   showdata: any = []; 
  details: any;
  unitOne: boolean = false; keys: any; defaultindex = 1;
   unitNames: any = [];
   unitIds: any = []; 
   headings: any = []; heads: any = [];
  allheading: any = []; singleUnit: any = []; uids: any = [];
  assetdata: any = [];
  mainHeading: any = {};
  againAssetdata: any;
  imageData: any;
  status1: boolean = false;
  status2: boolean = false;
  status3: boolean = false;
  status4: boolean = false;
  images: any = '';
  FacilityBanner: string;


  constructor(public commonservice: CommonService, private route: ActivatedRoute, private router: Router) {
    
  }
 

  ngOnInit() {
    // console.log(this.route.snapshot.params.id)
    this.route.queryParams.subscribe((res) => {
      // console.log(res)
      this.details = res.id;
    });
    
      this.details = this.route.snapshot.params.id
      this.details=this.details.replace(/-/g," ");
      // this.details=this.details.toUpperCase();
      // console.log(this.details)
    window.scrollTo(0, 0)

    this.get_data2();
    this.imagesrc = this.commonservice.basepath;
    this.imagesrc1 = this.commonservice.basepath;
    this.get_data();
    localStorage.setItem('id', this.details)
     
    
    this.carouselbanner1 = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      slide: 1,
      speed: 400,
      interval: 3000,
      point: { visible: false },
      load: 2,
      touch: true,
      loop: true
    }
  }

  get_data2() {
    this.commonservice.get_documents_banner().subscribe((res: any) => {
      this.showdata2 = res.data;
    })
  }
  get_data() {
    let data;
    this.commonservice.get_facilities().subscribe((res: any) => { 
      // console.log(this.details)
      // console.log(res)
    
      this.assetdata = res.data.filter(val =>val.unit_name.toLowerCase() == this.details);
      // this.assetdata=res.data.filter((item)=>{
      //   console.log(item);
      //   
      //   return (item.unit_name=this.details)
      // })
      // console.log(this.assetdata)
      
      data = res.data.filter(val => val.unit_name.toLowerCase() == this.details);
      this.assetdata = data.map((item, index) => {
        item.images = item.images.split(',');
        return item;
      });
      // console.log(this.assetdata)
      this.againAssetdata = this.assetdata;
      this.mainHeading = this.assetdata.find(x => x.unit_name.toLowerCase() == this.details);
      this.FacilityBanner=this.imagesrc+"/academicunits/"+this.mainHeading.unit_id+"/"+this.mainHeading.unit_image
      
    })
  }
  scrollFunction(id) {
    document.getElementById(id).scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
  }
}