import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-meitylogin',
  templateUrl: './meitylogin.component.html',
  styleUrls: ['./meitylogin.component.css']
})
export class MeityloginComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
