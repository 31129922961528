import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';

@Component({
  selector: 'app-mediacoverage',
  templateUrl: './mediacoverage.component.html',
  styleUrls: ['./mediacoverage.component.css']
})
export class MediacoverageComponent implements OnInit {
  showdata:any[];keys:any[];imagesrc:any;showdata1:any[];
  MediaBanner: string;
  constructor(public commonservice:CommonService,private http:HttpClient) { }

  ngOnInit() {
    window.scrollTo(0,0)
    this.get_data();
    this.get_data1();
    this.imagesrc= this.commonservice.basepath;
  }

  get_data(){
    this.commonservice.get_media().subscribe((res:any)=>{
    this.showdata =  res.data;
    this.keys = Object.keys(this.showdata);
  }) 
}

get_data1(){
  this.commonservice.get_media_banner().subscribe((res:any)=>{
  this.showdata1 =  res.data;
  this.MediaBanner=this.imagesrc+"/mediaCoverageBanner/"+this.showdata1[0].image_folder_name+"/"+this.showdata1[0].banner_image
  
}) 
}

}
