import { Component, OnInit } from "@angular/core";
import { CommonService } from "../service/common.service";
import * as _ from "lodash";

@Component({
  selector: "app-supportpartners",
  templateUrl: "./supportpartners.component.html",
  styleUrls: ["./supportpartners.component.css"]
})
export class SupportpartnersComponent implements OnInit {
  showdata: any = [];
  showdata2: any = [];
  imagesrc: any;
  keys: any[];
  constructor(public commonservice: CommonService) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    this.get_data2();
    this.imagesrc = this.commonservice.basepath;
    this.get_data();
  }

  get_data2() {
    this.commonservice.get_documents_banner().subscribe((res: any) => {
      this.showdata2 = res.data;
      
    });
  }

  get_data() {
    this.commonservice.get_supportPartners().subscribe((res: any) => {
      this.showdata = _.groupBy(res.data, function(b) {
        return b.type;
      });
      this.keys = Object.keys(this.showdata);
      
    });
  }
}
