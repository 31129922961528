import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';

@Component({
  selector: 'app-program-ait',
  templateUrl: './program-ait.component.html',
  styleUrls: ['./program-ait.component.css']
})
export class ProgramAitComponent implements OnInit {
  
  showdata:any=[];
  keys:any=[];
  imagesrc:any;

  constructor(public commonservice:CommonService,private http:HttpClient) { }

  ngOnInit() {
    window.scrollTo(0,0)
    this.get_data();
    this.imagesrc= this.commonservice.basepath;
  }

  get_data(){
    this.commonservice.get_prodetails().subscribe((res:any)=>{
    this.showdata =res.data;
    console.log("showdata",res)
    this.keys = this.showdata[1];
    console.log("keys",this.keys)
  }) 
}

}
