import { Component, OnInit } from "@angular/core";
import { CommonService } from "../service/common.service";

@Component({
  selector: "app-galleryear",
  templateUrl: "./galleryear.component.html",
  styleUrls: ["./galleryear.component.css"]
})
export class GalleryearComponent implements OnInit {
  constructor(private comm: CommonService) {}
  months: any;
  ngOnInit() {
    window.scrollTo(0, 0);
    this.comm.getYears().subscribe(res => {
      //  console.log("sisakdasd",res);
      this.months = res["data"];
    });
  }
}
