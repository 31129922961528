import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  showdata:any[];keys:any[];imagesrc:any;

  constructor(public commonservice:CommonService,private http:HttpClient) { }

  ngOnInit() {
    window.scrollTo(0,0)
    this.get_data();
    this.imagesrc= this.commonservice.basepath;
  }

  get_data(){
    this.commonservice.get_news().subscribe((res:any)=>{
    console.log(res.data)
      this.showdata = res.data;
    console.log(this.showdata)
    this.keys = Object.keys(this.showdata);
    console.log(this.keys)
  }) 
}

}
