// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  frontURL: "https://sineiitb.org/",
  api_url: "https://sineiitb.org/sinemaster/api",
  baseURL: "https://sineiitb.org/sinemaster/resources/images",
  // api_url: "https://sineiitb.org/sinemaster_girish/api",
  // baseURL: "https://sineiitb.org/sinemaster_girish/resources/images",

  //Infinitech Server
  // frontURL: "https://infinitechlabs.in/demo/sine_new11/",
  // frontURL: "https://infinitechlabs.in/demo/sine_new/",
  // frontURL: "https://localhost:4200",
  // api_url: "https://infinitechlabs.in/demo/sinemaster/api",
  // baseURL: "https://infinitechlabs.in/demo/sinemaster/resources/images"
  // api_url: "https://www.sineiitb.org/sine_master/api",
  // baseURL: "https://www.sineiitb.org/sine_master/resources/images",
  // frontURL: "http://infinitechlabs.in/demo/sine_new",
  // frontURL: "http://infinitechlabs.in/demo/sine_beta"

  // Local
  // api_url: "http://192.168.29.182/sine_master/api",
  // baseURL: "http://192.168.29.182/sine_master/resources/images",
  // frontURL: "http://localhost/sine/",

  ///tesst///

  // api_url: "https://localhost/sinemaster/api",
  // baseURL: "https://localhost/sinemaster/resources/images",
  // frontURL: "https://localhost/frontend/",
  // //////////////

  // api_url : "https://localhost/suraj/sinemaster1/api",
  // baseURL : "https://localhost/suraj/sinemaster1/resources/images",

  // Live

  // api_url: "https://localhost/sinemaster/api",
  // baseURL: "https://localhost/sinemaster/resources/images",
 // frontURL:"https://localhost/4200",

  // api_url : "https://www.sineiitb.org/sine_master/api",
  // baseURL : "https://www.sineiitb.org/sine_master/resources/images",
  //frontURL: "https://www.sineiitb.org/sine",

  // Test
  // api_url : "https://www.sineiitb.org/sine_master_test/api",
  // baseURL : "https://www.sineiitb.org/sine_master_test/resources/images",

  //api_url: "https://localhost/sinemaster/api",
  //baseURL: "https://localhost/sinemaster/resources/images",
  // frontURL:"https://localhost/4200",

  // api_url: "https://sineiitb.org/sinemaster_girish/api",
  // baseURL: "https://sineiitb.org/sinemaster_girish/resources/images",
  // frontURL:"https://sineiitb.org/",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
