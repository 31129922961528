import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
public path = environment.api_url;
public basepath = environment.baseURL;
public frontpath = environment.frontURL;
changename = new Subject<any>();
namechanged = this.changename.asObservable();
constructor(private http:HttpClient) { }

  get_details(){
  //  this.http.get('http://localhost/sine_master/api/v1/BoardOfDirectors/listing')
  return this.http.get('/v1/BoardOfDirectors/listing')
  }

  get_teamdetails(){
    return this.http.get('/v1/Team/listing')
  }

  get_prodetails(){
    return this.http.get('/v1/Programmes/listing/')
  }

  get_portfoliomaintabs(){
    return this.http.get('/v1/PortfolioCompanyType/listing/')
  }

  get_PortfolioCompanyTab(){
    return this.http.get('/v1/PortfolioCompany/listing/')
  }

  get_portfolioTabs(){
    return this.http.get('/v1/Portfolio/listing/')
  }

  get_supportPartners(){
    return this.http.get('/v1/SupportPartners/listing/')
  }

  get_homeBanners(){
    // console.log(this.http);
    return this.http.get('/v1/HomeBanner/listing/')
  }

  get_homeEvents(){
    return this.http.get('/v1/Events/listing/')
  }

  get_aboutContent(){
    return this.http.get('/v1/Home/listing/')
  }
  get_resources(){
    return this.http.get('/v1/Resources/listing/')
  }

  get_iitbpage(){
    return this.http.get('/v1/Iitb/listing/')
  }

  get_footer(){
    return this.http.get('/v1/FooterLinks/listing/')
  }

  get_impactContent(){
    return this.http.get('/v1/Impact/listing/')
  }
  
  get_contact(){
    return this.http.get('/v1/ContactUs/listing/')
  }

  get_menu(){
    return this.http.get('/v1/Navigation/listing/')
  }

  get_advisory(){
    return this.http.get('/v1/ProgrameAdvisors/listing')
  }
  get_mentors(){
    return this.http.get('/v1/ProgrameMentors/listing')
  }

  get_media(){
    return this.http.get('/v1/MediaCoverage/listing/')
  }

  get_media_banner(){
    return this.http.get('/v1/MediaBanner/listing/')
  }
  get_team_banner(){
    return this.http.get('/v1/BannerImage/Team/')
  }
  get_whatwe_banner()
  {
    return this.http.get('/v1/BannerImage/What_we_do/')
  }
  get_bod_banner(){
    return this.http.get('/v1/BannerImage/Board_of_director/')
  }
  get_advisory_banner(){
    return this.http.get('/v1/BannerImage/Start_up_advisory/')
  }
  get_mentors_banner(){
    return this.http.get('/v1/BannerImage/Start_up_mentors/')
  }
  get_faq_banner(){
    return this.http.get('/v1/BannerImage/Faq/')
  }
  get_portfolio_banner(){
    return this.http.get('/v1/BannerImage/Portfolio/')
  }
  get_documents_banner(){
    return this.http.get('/v1/BannerImage/Resources/')
  }
  get_contact_banner(){
    return this.http.get('/v1/BannerImage/Connect_With_Us/')
  }

  get_news(){
    return this.http.get('/v1/Newsletter/listing/')
  }

  get_careerslisting(){
    return this.http.get('/v1/Careers/listing/')
  }

  get_careerimage(){
    return this.http.get('/v1/CareersImage/listing/')
  }

  get_facilities(){
    return this.http.get('/v1/Facilities/listing/')
  }

  get_facilitiesPara(){
    return this.http.get('/v1/iitb/link')
  }
  get_facilitiesBanner(){
    return this.http.get('/v1/BannerImage/Facilities')
  }

  get_tendorlist(){
    return this.http.get('/v1/Tender/listing/')
  }

  get_tenderArchivelist(){
    return this.http.get('/v1/Tender/archiveTenderListing/')
  }

  get_tender(id){
    // console.log(id)
    return this.http.get('/v1/Tender/details',{params:{id:id}});
  }

  get_tender1(id){
    // console.log(id)
    return this.http.get('/v1/Tender/detail_file',{params:{id:id}});
  }

  get_testimonial(){
    return this.http.get('/v1/testimonial/listing')
  }

  getYears(){
    return this.http.get('/v1/gallery/getYears')
  }

  get_galleryData(year){
    return this.http.get('/v1/gallery/listing/'+year)
  }
  get_whatwedo(){
    return this.http.get('/v1/what_we_do/listing')
  }
  get_blinker(){
    return this.http.get('/v1/Blinker/listing/')
  }

  post_userlogin(data){
    return this.http.post(this.path+'/v1/Userlogin/check_login/',{params:{uname:data.uname,upass:data.pass}},{
      headers: {
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache'
}
});
    // return data;
    // return this.http.post(this.path+'/v1/Userlogin/check_login/',{params:{uname:data.uname,upass:data.pass}},{
    //         headers: {
    //     'Content-Type': 'application/json',
    //     'Cache-Control': 'no-cache'
    //   }
    // });
  }
  postData(data)
  {
    return this.http.post(this.path+'/v1/Userlogin/check_login',data, {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Accept': 'application/json',
      }
    });
  }
  post_subscriber(data)
  {
    return this.http.post(this.path+'/v1/SubscribeNewsletter/Subscribe',data, {
      headers: {
        'Content-Type': 'text/plain',
        'Cache-Control': 'no-cache',
        'Accept': 'application/json',
      }
    });
  }
  get_usercom_data(){
    return this.http.get('/v1/Meity_fund_status/listing')
  }
  get_user_data(){
    return this.http.get('/v1/Total_fund/listing')
  }

change_name(data){
  this.changename.next(data);
}
  // get_submenus(){
  //   return this.http.get('/v1/MenuChildren/listing/')
  // }



}
