import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(public http:HttpClient) { }

  getcont(data){
      console.log(data);
        let input = new FormData();
        // Add your values in here
        input.append('name', data.name);
        input.append('emailid', data.emailid);
        input.append('org', data.org);
        input.append('phonenum', data.phonenum);
        input.append('comment', data.comment);

      // return this.http.post('http://www.cloudesigntechnologies.com/email/contact.php',input);

      return this.http.post('https://www.sineiitb.org/email/contact.php',input);

  }
}
