import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  tab:string ='acc1';
  showdata: any = [];
  imagesrc:any;
  topBanner: any;
  constructor(public commonservice:CommonService) { }

  ngOnInit() {
    window.scrollTo(0,0)
    this.get_data();
    this.imagesrc = this.commonservice.basepath;
  }

  accClick(value:string)
  {
    this.tab =value;
  }

  adjustHeight(id:any)
  {
    // alert(id);
    var el = document.getElementById(id);
  }
  
  get_data(){
    this.commonservice.get_faq_banner().subscribe((res:any)=>{
    this.showdata =  res.data;
    this.topBanner=this.imagesrc+"/bannerImage/"+this.showdata[0].image_folder_name+"/"+this.showdata[0].image
  }) 
  }

}
