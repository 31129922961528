import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  ViewChildren,
  AfterViewInit
} from "@angular/core";
import { NguCarousel } from "@ngu/carousel";
import * as tabs from "tabs";
import { log } from "util";
import { CommonService } from "../service/common.service";
import * as _ from "lodash";
import { ActivatedRoute, Router } from "@angular/router";
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"]
})
export class HomeComponent implements OnInit {
  public carouselbanner: NguCarousel;
  public carouselbanner1: NguCarousel;
  require: any;
  filtervalue: any;
  k: any;
  programsArr: any = [];
  announcementcount: number = 1;
  eventsArr: any = [];
  bannerArr: any = [];
  aboutusArr: any = [];
  showdata: any = [];
  bannerdata: any = [];
  eventdata: any = [];
  aboutdata: any = [];
  progdata: any = [];
  aboutsdata: any = [];
  para1: any;
  keys: any = [];
  bannerkeys: any = [];
  eventkeys: any = [];
  aboutkeys: any = [];
  progkeys: any = [];
  aboutskeys: any = [];
  tab: string = "acc1";
  read: boolean = false;
  imagesrc: any;
  urlpath: any;
  impactdata: any = [];
  impactkeys: any = [];
  mediadata: any = [];
  mediakeys: any = [];
  aboutRoute: any;
  progRoute: any;
  sp_partner: any = [];
  op_partner: any = [];
  homeImg: string;
  time_from1:any ='13:11:53';

  constructor(
    private elemrf: ElementRef,
    public commonservice: CommonService,
    private route: ActivatedRoute,
    public router: Router
  ) {}
  @ViewChild("accordion") elemh: ElementRef;
  @ViewChild("about") aboutSection: ElementRef;
  @ViewChild("programs") programSection: ElementRef;
  @ViewChild("events") events: ElementRef;
  tabLinks: any;
  ngOnInit() {
    this.route.data.subscribe(v =>
      v.scrollTo ? (this.aboutRoute = v.scrollTo) : ""
    );
    if (
      (localStorage.getItem("tab") != null &&
        localStorage.getItem("tab") == "about") ||
      this.aboutRoute == "about"
    ) {
      setTimeout(() => {
        this.scroll(this.aboutSection.nativeElement);
      }, 1000);
    }
    this.route.data.subscribe(v =>
      v.scrollTo ? (this.progRoute = v.scrollTo) : ""
    );
    if (
      (localStorage.getItem("tab") != null &&
        localStorage.getItem("tab") == "programs" &&
        localStorage.getItem("tab") == "programs") ||
      this.progRoute == "programs"
    ) {
      setTimeout(() => {
        this.scroll(this.programSection.nativeElement);
      }, 1000);
      
    }
    this.carouselbanner = {
      grid: { xs: 1, sm: 2, md: 3, lg: 3, all: 0 },
      slide: 1,
      speed: 400,
      interval: 4000,
      point: { visible: false },
      load: 2,
      touch: true,
      loop: true
    };
    this.carouselbanner1 = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      slide: 1,
      speed: 400,
      interval: 4000,
      point: { visible: true },
      load: 2,
      touch: true,
      loop: true
    };
    this.getTabs();
    this.get_data();
    this.get_banners();
    this.get_events();
    this.get_progs();
    this.imagesrc = this.commonservice.basepath;
    this.urlpath = this.commonservice.frontpath;
    this.get_aboutinfo();
    this.get_aboutinfos();
    this.get_mediadata();
  }
  scroll(el) {
    window.scrollTo(0, el.offsetTop - 50);
    localStorage.removeItem("tab");
  }
  accClick(value: string) {
    this.tab = value;
  }
  getTabs() {
    var container = document.querySelector(".bhoechie-tab-container");
    tabs(container);
    
  }
  animateflip(curtag: number, e) {
    this.filtervalue = curtag;
    let elem = this.elemrf.nativeElement.querySelectorAll(".card");
    for (this.k = 0; this.k <= elem.length - 1; this.k++) {
      elem[this.k].children[1].classList.remove("show");
      elem[this.k].children[1].classList.add("collapse");
    }
    elem[curtag].children[1].classList.remove("collapse");
    
  }
  announcement(curtag) {
    this.announcementcount = curtag;
  }
  readmore() {
    this.read = true;
  }
  readless() {
    this.read = false;
  }
  scrollThis1(key) {
    
    if (key == "about") {
      this.scroll(this.aboutSection.nativeElement);
    }
    if (key == "programs") {
      this.scroll(this.programSection.nativeElement);
    }
  }
  public gotoSection() {
    
    this.events.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "center"
    });
  }
  get_data() {
    this.commonservice.get_supportPartners().subscribe((res: any) => {
      if (res.rc === true) {
        const data = _.groupBy(res.data, function(b) {
          return b.type;
        });
        this.showdata = data["Support Partners"];
      }
  });
  }
  get_banners() {
    this.commonservice.get_homeBanners().subscribe((res: any) => {
      this.bannerdata = _.groupBy(res.data, function(b) {
        return b.rank;
      });
      this.bannerkeys = Object.keys(this.bannerdata);
    });
  }
  get_events() {
    this.commonservice.get_homeEvents().subscribe((res: any) => {
      this.eventdata = res.data;
      this.eventkeys = Object.keys(this.eventdata);
      // console.log(this.eventdata)
      
    });
  }
  getchange(menu) {
    
    this.router.navigateByUrl("/programmes"+'/'+ menu.replace(/\s+/g, "-").toLowerCase());
    localStorage.setItem("change route", menu);
    this.commonservice.change_name(menu)
  }

  getchange1(menu1) {
  
    if (menu1.event_link == "/events") {
    
      this.router.navigate(["/events"], { queryParams: { id: menu1.id } });
    } else {
      this.router.navigateByUrl("/" + menu1.event_link);
      localStorage.setItem("change route", "SINE INCUBATION");
    }
    
  }
  get_aboutinfo() {
    this.commonservice.get_impactContent().subscribe((res: any) => {
      this.impactdata = res.data;
      this.impactkeys = Object.keys(this.impactdata);
    });
  }
  get_aboutinfos() {
    this.commonservice.get_aboutContent().subscribe((res: any) => {
      this.aboutsdata = res.home_details.data[0];
      this.homeImg=this.imagesrc+"/"+"about_sine/"+this.aboutsdata.about_sine_image
      this.para1 = this.aboutsdata.overview.split("</p>");
    });
  }
  get_progs() {
    this.commonservice.get_prodetails().subscribe((res: any) => {
      this.progdata = res.data;
      this.progkeys = Object.keys(this.progdata);
    });
  }
  get_mediadata() {
    this.commonservice.get_media().subscribe((res: any) => {
      if (res.rc) {
        this.mediadata = res.data;
        this.mediakeys = Object.keys(this.mediadata);
      }
    });
  }
}