import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.css']
})
export class BoardComponent implements OnInit {
showdata;
showdata1: any = [];
imagesrc:any;
image_folder_name:any;
image:any;
  BoardBanner: string;
  constructor(public commonservice:CommonService,private http:HttpClient) { }

  ngOnInit() {
    window.scrollTo(0,0)
    this.get_data();
    this.get_data2();
    this.imagesrc = this.commonservice.basepath;
  }

  get_data(){
    this.commonservice.get_details().subscribe((res:any)=>{
      // console.log(res);
      this.showdata=res.data[0].details;
    }) 
  }
  get_data2(){
    this.commonservice.get_bod_banner().subscribe((res:any)=>{
    this.showdata1 =  res.data;
    this.image_folder_name = this.showdata1[0].image_folder_name;
    this.image = this.showdata1[0].image;
    this.BoardBanner=this.imagesrc+"/bannerImage/"+this.image_folder_name+"/"+this.image
    
  }) 
  }


}
