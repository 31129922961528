import { Component, OnInit } from '@angular/core';
import { Spinkit } from 'ng-http-loader';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  public spinkit = Spinkit;
  title = 'SINE';

  constructor(private titleService: Title,private metaService: Meta) {
    
  }

  ngOnInit() {
    // this.titleService.setTitle(this.title);
    // this.metaService.addTag({name: 'author', content: 'Angular, Universal, Example'}),
    // this.metaService.addTag({name: 'description', content: 'Angular Universal Example'}),
    // this.metaService.updateTag({name: 'keywords', content: 'Angular, Universal, Example'}),
    // this.metaService.updateTag({name: 'keywords', content: 'Angular, Universal, Example'}),


    
    window.scrollTo(0,0)
  }
//   onActivate(event) {
//     let scrollToTop = window.setInterval(() => {
//         let pos = window.pageYOffset;
//         if (pos > 0) {
//           window.scrollTo(0,0); // how far to scroll on each step
//         } else {
//             window.clearInterval(scrollToTop);
//         }
//     }, 16);
// }
}
