import { Component, OnInit, ViewChild, ElementRef, Pipe, PipeTransform } from '@angular/core';
import { CommonService } from '../service/common.service';
import { environment } from '../../environments/environment';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
// import { EmbedVideoService } from 'ngx-embed-video';
import { NguCarousel } from "@ngu/carousel";
// import { DomSanitizer } from @angular/platform-browser;

// import * as _ from "flickity-as-nav-for";
import * as _ from "lodash";
declare var $: any;
declare var jQuery: any;
declare var flkty: any;
// import * as _  from 'ngx-flickity';
// @Pipe({
//   name: 'safePipe'
// })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(link: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(link);
  }
}
@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.css']
})

export class TestimonialComponent implements OnInit {
  tab: any = 'tab1';
  tab1: any;
  tab2: any;
  Clicked: boolean

  onClick(check) {
    console.log(check);
    if (check == 1) {
      this.tab = 'tab1';
    } else if (check == 2) {
      this.tab = 'tab2';
    }
  }

  toTop() {
    document.getElementById("content").scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  public carouselbanner: NguCarousel;
  // public basepath = this.sanitizer.bypassSecurityTrustResourceUrl(environment.baseURL);
  public basepath = environment.baseURL;
  public testData: any = [];
  public textData: any;
  textType: any;
  keys: any[]
  i: any;
  showSelected: boolean;
  @ViewChild('videoPlayer') videoplayer: ElementRef;



  // public ElementRef:any[];
  // @ViewChild('video') video: ElementRef;
  n: any;
  topvid: any;
  imagesrc: any;
  constructor(private comm: CommonService, protected sanitizer: DomSanitizer) { }


  // public transform(link: any, type: string): SafeUrl | SafeResourceUrl {
  //   switch (type) {
  // 		case 'url': return this.sanitizer.bypassSecurityTrustUrl(link);
  // 		case 'resourceUrl': return this.sanitizer.bypassSecurityTrustResourceUrl(link);
  // 		default: throw new Error(`Invalid safe type specified: ${type}`);
  // 	}
  // }
  transform(url) {

    return this.sanitizer.bypassSecurityTrustResourceUrl(url)

  }
  // flickity = {"asNavFor":".gallery-a"}; 
  ngOnInit() {
    window.scrollTo(0, 0)
    let array = []
    this.comm.get_testimonial().subscribe((res) => {
      this.showSelected = false;
      this.testData = res['data'];
      console.log(this.testData)
      this.testData.forEach(element => {
        if (element.type === 'text') {
          array.push(element)
          this.textType = element.type
        }
      });
      let imageObject = []; // your other array...

      this.testData.map(item => {
        return {
          video: item.link
          // image: item.testimonial_image
        }
      }).forEach(item => imageObject.push(item));
      this.textData = array;
      console.log(JSON.stringify(imageObject))

    })

  }
  getSantizeUrl(link: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(link);
  }
  ngAfterViewInit() {
    // ...

    // })
  }
}
