import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-infra-gallery',
  templateUrl: './infra-gallery.component.html',
  styleUrls: ['./infra-gallery.component.css']
})
export class InfraGalleryComponent implements OnInit {

  constructor() { }
  showdata = [
    { imagesrc: "../../assets/images/infragallery/conferenceroom.jpg", alt: 'Image 1' ,name:"Conference Room"},
    { imagesrc: "../../assets/images/infragallery/meetingroom.jpg", alt: 'Image 2',name:"Meeting Room" },
    { imagesrc: "../../assets/images/infragallery/meetingroom1.jpeg", alt: 'Image 3',name:"Meeting Room" },
    { imagesrc: "../../assets/images/infragallery/officespace.JPG", alt: 'Image 4',name:"Office Space" }, 
    { imagesrc: "../../assets/images/infragallery/officespace1.jpg", alt: 'Image 5',name:"Office Space" },
    { imagesrc: "../../assets/images/infragallery/protolab.jpg", alt: 'Image 6',name:"Prototyping Lab" },
    { imagesrc: "../../assets/images/infragallery/metallab.jpg", alt: 'Image 7',name:"MetalWorking Lab" },
    { imagesrc: "../../assets/images/infragallery/biolab.png", alt: 'Image 8',name:"Bio Lab" },
  
    // etc.
  ];
  ngOnInit() {
  }

}
