import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partner-offering',
  templateUrl: './partner-offering.component.html',
  styleUrls: ['./partner-offering.component.css']
})
export class PartnerOfferingComponent implements OnInit {

  constructor() { }

  buttonClickEvent(id){
    console.log(id);
    document.getElementById(id).scrollIntoView();
  }
  benifits = [
    {
      "id" : "_cloud",
      "name" : "Cloud",
      "image_url" : "../../assets/images/benifits/cloud.png",
      "description":"Get up to $150,000 worth of cloud credits.",
      "click_url":"/offering",
      "items" : [
     
        {
          "ename" : "AWS",
          "eimage_url" : "../../assets/images/benifits/aws.jpg",
          "eclick_url" : `https://4hs3rzdz.r.us-east-1.awstrack.me/L0/https:%2F%2Fconsole.aws.amazon.com%2Factivate%2F%3F%23%2Fapply/1/0100018356fff6bf-3b509368-e486-48c0-86f9-86f35e772940-000000/6JFiaGe-QBha2ODdtc3mNQzsyT8=287`,
          "cat_id":"1",
          "edescription" : "AWS Activate Portfolio is a free program designed for startups and early stage entrepreneurs that provides $5,000 in AWS credits, AWS Support credits, training credits, exclusive offers, go-to-market support and more. These benefits are designed to give you the right mix of tools, resources and expert support to quickly get started on AWS and grow your business.",

        },
        {
          "ename" : "Google",
          "eimage_url" : "../../assets/images/benifits/Google.jpg",
          "eclick_url" : `https://inthecloud.withgoogle.com/startup/dl-cd.html?utm_source=ecosystem_partners&utm_medium=partner&utm_campaign=FY21-Q1-global-demandgen-website-cs-startup_program_mc&utm_content=sine_iit_bombay&utm_term=-`,
          "cat_id":"1",
          "edescription" : "Google Cloud is offering access to startup experts, Cloud cost coverage (up to $100,000 for each of the first two years), technical training, business support, and Google-wide offers through their Start & Scale programs.",

        },
        {
          "ename" : "Microsoft",
          "eimage_url" : "../../assets/images/benifits/Microsoft.jpg",
          "eclick_url" : "https://societyofexcellence.in/",
          "cat_id":"1",
          "edescription" :`Build your startup on Azure!
          Azure Society of Excellence is offering cloud credits worth up to $150,000 redeemable over time, access to Microsoft Technology Centre, Founders Hub, MS Teams Community platform and MS Enterprise network. The support you need, just when you need it.`,
        },
        {
          "ename" : "Oracle",
          "eimage_url" : "../../assets/images/benifits/Oracle.png",
          "eclick_url" : "#",
          "cat_id":"1",
          "edescription" : "The ‘Oracle for Scaleups’ is an invite only - accelerate program designed for mid-to-late stage B2B and B2B2C startups ready to Build, Scale and Connect with Oracle. Benefits include free cloud credits, multi-year deep discounts on Oracle cloud, 50% discounts on Oracle Partner Network (OPN)  programs, access to SaaS products, customer connect and channel connect. ",
        },
      ]
    },
    {
      "name" : "Development Tools",
      "image_url" : "../../assets/images/benifits/development_tools.png",
      "description":"Get up to $10,000 worth of development tool credits.",
      "click_url":"/",
      "id" : "_dev_tools",
      "items" : [
        {
          "ename" : "Altair",
          "eimage_url" : "../../assets/images/benifits/altair.png",
          "eclick_url" : "",
          "cat_id":"1",
          "edescription" :`Altair is offering startups 6 months of free license to access its full stack of product suite that includes tools like Data Analytics (AI/ML), Math and System models, Detailed Engineering Simulations and Manufacturing Simulations. SINE startups are also eligible to buy Altair products at 50% discount on lease models under the Startup India Scheme.`,

        },
        {
          "ename" : "Freshworks",
          "eimage_url" : "../../assets/images/benifits/freshworks.png",
          "eclick_url" : "https://www.freshworks.com/partners/startup-program/sineiitb/",
          "cat_id":"1",
          "edescription" :`Freshworks is a leading cloud-based customer and employee engagement software company based in San Mateo, California. SINE IIT Bombay has partnered with Freshworks for Startups and our portfolio companies can now avail
          - $10000 credits across the Freshworks suite of products
          - Access to mentorship, resources, investor connects and much more.
          `,

        },
        {
          "ename" : "GitHub",
          "eimage_url" : "../../assets/images/benifits/github.png",
          "eclick_url" : "https://resources.github.com/startups/?pid=sine",
          "cat_id":"1",
          "edescription" :"GitHub helps startups plan, track, collaborate, build, test, and deploy software with a holistic and secure platform that scales with them. As part of GitHub for Startups, eligible startups receive up to 20 seats of GitHub Enterprise free for one year plus 50% off in year two, access to GitHub experts for office hours, live demos, and technical best practice sharing.",

        },
        {
          "ename" : "Mathworks",
          "eimage_url" : "../../assets/images/benifits/mathworks.png",
          "eclick_url" : "http://www.mathworks.com/startups",
          "cat_id":"1",
          "edescription" :`Mathworks is offering SINE startups up to 12 months free software; MATLAB, Simulink and 90+ add-on products, up to 10 licenses per company, full technical support (phone, email, online), and free training of MATLAB, Simulink and deep learning onramps, online self-paced tutorials and 50% off Training Credits usable for all on-line and public classes.`,

        },
        {
          "ename" : "Mongo DB",
          "eimage_url" : "../../assets/images/benifits/mongodb.png",
          "eclick_url" : "https://www.mongodb.com/startups",
          "cat_id":"1",
          "edescription" :"MongoDB for Startups is focused on creating a valuable technical startup program that enables the success of high-growth startups from ideation to IPO. This program offers credits for all MongoDB Cloud products, a dedicated technical advisor, co-marketing opportunities and access to MongoDB Developer ecosystem to SINE startups.",

        },

      ]
    },
    {
      "name" : "Hardware",
      "image_url" : "../../assets/images/benifits/hardware.png",
      "description":"Get exclusive offers for your hardware product requirements.",
      "click_url":"",
      "id" : "_hw_tools",
      "items" : [
        {
          "ename" : "DELL",
          "eimage_url" : "../../assets/images/benifits/Dell.png",
          "eclick_url" : "https://www.dell.com/en-in/work/lp/dell-for-startups",
          "cat_id":"1",
          "edescription" :`Dell For Startups an exclusive program for startups enabling startups to harness the power of next-gen technological solutions, with our comprehensive end-to-end support system, and helping them scale up. This includes access to exclusive bundled offers for Dell products, IT advisory and consultation, knowledge series and mentorship program.`,

        },

      ]
    },
    {
      "name" : "Debt Financing",
      "image_url" : "../../assets/images/benifits/debt.png",
      "description":"Get up to INR 2 Cr of collateral free debt funding.",
      "click_url":"",
      "id" : "_debt_financing_tools",
      "items" : [
        {
          "ename" : "Indian Bank",
          "eimage_url" : "../../assets/images/benifits/industrial.png",
          "eclick_url" : "#",
          "cat_id":"1",
          "edescription" :`Indian Bank provides collateral free debt funding of up to INR 2 Cr to eligible startups as part of CGTMSE Scheme.`,

        },

      ]
    },
    {
      "name" : "Other Enterprise Tools",
      "image_url" : "../../assets/images/benifits/HR_tools.png",
      "description":"Get up to 1 year free access to enterprise tools.",
      "click_url":"",
      "id" : "_hr_tools",
      "items" : [
        {
          "ename" : "Babblebots",
          "eimage_url" : "../../assets/images/benifits/babble.jpg",
          "eclick_url" : "https://www.babblebots.ai/",
          "cat_id":"1",
          "edescription" :`Babblebots AI-Recruiters super charge your hiring using our patent-pending voice-platform. We help you hire right, lightning fast and at a fraction of the cost. 
          As an introductory offer, we offer First Month Free to SINE Companies when you sign-up for 3 months.`,

        },
        {
          "ename" : "LineUp Technologies",
          "eimage_url" : "../../assets/images/benifits/Lineup.png",
          "eclick_url" : "https://lineupx.com/",
          "cat_id":"1",
          "edescription" :`Lineup Technology Services a no-code/low-code platform that simplifies creating and managing job boards. It offers professional templates, customization options, SEO, a centralized candidate database and collaboration tools for an easier hiring process. Additional benefits: Free consultation for startups. SINE refered companies to  get discounts between 10-20%.`,

        },
        {
          "ename" : "SWIPE",
          "eimage_url" : "../../assets/images/benifits/swipe.jpg",
          "eclick_url" : "http://www.getswipe.in/startups",
          "cat_id":"1",
          "edescription" :`With Swipe, create invoices, purchases, & quotations in less than 10 seconds and share them directly with customers/ vendors via WhatsApp & Email. Manage your billing, payments, accounting, stock, & GST filings easily. #SwipeforStartups offers a 12-month subscription (Worth INR 7,499/-) at absolutely ZERO cost for Startups associated with SINE, & an exclusive 30% OFF on all plans after trial.`,

        },


      ]
    },
  ];
  selectedItem={};

  // subBenifits = [
  //   {
  //     "name" : "Cloud",
  //     "id" : "_cloud",
  //     "items" : [
  //       {
  //         "name" : "AWS",
  //         "image_url" : "../../assets/images/logos/aws-logo.png",
  //         "click_url" : "",
  //         "cat_id":"1",
  //         "description" : "Cloud #2AWS Activate Portfolio is a free program designed for startups and early stage entrepreneurs that provides $10,000 in AWS credits, AWS Support credits, training credits, exclusive offers, go-to-market support and more. These benefits are designed to give you the right mix of tools, resources and expert support to quickly get started on AWS and grow your business.",

  //       },
  //       {
  //         "name" : "Oracle",
  //         "image_url" : "../../assets/images/logos/aws-logo.png",
  //         "click_url" : "",
  //         "cat_id":"1",
  //         "description" : "The ‘Oracle for Scaleups’ is an invite only - accelerate program designed for mid-to-late stage B2B and B2B2C startups ready to Build, Scale and Connect with Oracle. Benefits include free cloud credits, multi-year deep discounts on Oracle cloud, 50% discounts on Oracle Partner Network (OPN)  programs, access to SaaS products, customer connect and channel connect. ",
  //       },
  //       {
  //         "name" : "Google",
  //         "image_url" : "../../assets/images/logos/aws-logo.png",
  //         "click_url" : "",
  //         "cat_id":"1",
  //         "description" : "Google Cloud is offering access to startup experts, Cloud cost coverage (up to $100,000 for each of the first two years), technical training, business support, and Google-wide offers through their Start & Scale programs",
  //       },
  //     ]
  //   }
  // ];
  ngOnInit() {
    window.scrollTo(0, 0);
    }

}
