import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators } from '@angular/forms';
import { ContactService } from '../contact.service';

import * as _ from 'lodash';
import { Router } from '@angular/router';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  showRegister:boolean = false;
  buttonsubmit: boolean;imagesrc:any;showdata2:any = [];
  topBanner: string;

  constructor(public contservice: ContactService, public fb: FormBuilder, public commonservice:CommonService, public router:Router) { }

  profileForm = this.fb.group({
    name: ['', [Validators.required]],
    org: ['', [Validators.required]],
    emailid: ['', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/)]],
    phonenum: ['', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],
    comment: ['', [Validators.required]]
  });



  ngOnInit() {
    this.get_data2();
    this.imagesrc= this.commonservice.basepath;
  }
  get_data2(){
    this.commonservice.get_contact_banner().subscribe((res:any)=>{
    this.showdata2 =  res.data;
    // console.log("team-banner",this.showdata2)
    this.topBanner=this.imagesrc+"/bannerImage/"+this.showdata2[0].image_folder_name+"/"+this.showdata2[0].image
  }) 
  }
  ValidateAllFormFields(formGroup: any) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.ValidateAllFormFields(control);
      }
      else if (control instanceof FormArray) {
        this.ValidateAllFormFields(control)
      }

    });
  }
  onSubmit(value) {
    // console.log(value);
    if (this.profileForm.valid) {
      // console.log(this.profileForm.value);
      this.contservice.getcont(value).subscribe((res) => {
        console.log(res);
        this.buttonsubmit = true;
        this.profileForm.reset();
        setTimeout(() => {
          this.buttonsubmit = false
        }, 5000);
      },(err:any)=>{
        console.log(err);
      })

    }
    else {
      this.ValidateAllFormFields(this.profileForm)
    }
  }
  connectUs()
  {
    this.router.navigate(['connectus']);
  }

  registerPage(){
    // this.showRegister = true;
    this.router.navigate(['register']);
  }

  // enquiry(){
  //   // this.showRegister = true;
  //   this.router.navigate(['enquiry']);
  // }
  

}
