import { Component, OnInit } from "@angular/core";
import { Gallery } from "ng-gallery";
import { map } from "rxjs/operators";
import { CommonService } from "../service/common.service";
import { ActivatedRoute } from "@angular/router";
import * as _ from "lodash";

@Component({
  selector: "app-gallery",
  templateUrl: "./gallery.component.html",
  styleUrls: ["./gallery.component.css"]
})
export class GalleryComponent implements OnInit {
  imagesrc: any;
  img: string;
  errorShow: boolean;
  // version = VERSION;
  constructor(
    public gallery: Gallery,
    private comm: CommonService,
    private route: ActivatedRoute
  ) { }
  image: any;
  showdata: any;
  keys: any[];
  ngOnInit() {
    this.errorShow=false;
    window.scrollTo(0, 0);
    this.imagesrc = this.comm.basepath;

    const year = this.route.snapshot.params.year;
    this.showImages(year);
  }

  viewimage(file_name, id) {
    this.img = `${this.imagesrc}/gallery/${id}/${file_name}`;
    var modal = document.getElementById("myModal");
    var modalImg = document.getElementById("img01");
    modal.style.display = "block";
    modalImg['src'] = this.img;

  }
  onclick() {
    var modal = document.getElementById("myModal");
    modal.style.display = "none";
  }
  showImages(year) {
    this.comm.get_galleryData(year).subscribe(res => {
      if (res["rc"]) {
        this.showdata = res["data"];
      } else {
        this.errorShow=true;
      }
    });
  }
}