import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../service/common.service';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-archivetenders',
  templateUrl: './archivetenders.component.html',
  styleUrls: ['./archivetenders.component.css']
})
export class ArchivetendersComponent implements OnInit {
  showdata:any[];keys:any[];
  showdatedata:any=[];showdatekeys:any[];
  blink : boolean = false;
  constructor(public commonservice:CommonService,private router : Router) { }

  ngOnInit() {
    this.get_data();
  }

  get_data1(){
    this.commonservice.get_tendorlist().subscribe((res:any)=>{
    var test = res.data.sort(function(a,b){
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      var c : any  = new Date(b.created_on);
      var d : any = new Date(a.created_on);
      return c-d;
    })[0];
    console.log('test',test)
    var today = new Date();
    console.log('today',today)
    var yesterday = new Date(new Date().getTime() - (24*60*60*1000))
    console.log('yesterday',yesterday)
      if(new Date(test.due_date) <= new Date(test.due_date)){
        this.showdatedata =  res.data;
        this.showdatedata.map( i => { 
          return i.due_date = moment(i.due_date).format("DD-MMMM-YYYY");
        })
      }
    console.log('hfdhdh',test)

    console.log("date",this.showdatedata)
    this.showdatekeys = Object.keys(this.showdatedata); 
  })
  }

  get_data(){
    this.commonservice.get_tenderArchivelist().subscribe((res:any)=>{
      this.showdata =  res;
      console.log(res);
      this.showdata.map( i => { 
        return i.due_date = moment(i.due_date).format("DD-MMMM-YYYY");
      })
    })
  }
}