import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { environment } from '../../environments/environment';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-whatwedo',
  templateUrl: './whatwedo.component.html',
  styleUrls: ['./whatwedo.component.css']
})
export class WhatwedoComponent implements OnInit {
  public basepath = environment.baseURL;
  public testData:any= [];
  keys: any[];
  showdata: any = [];imagesrc:any;
  topBanner: any;
  topImg:any;
  topSide:any;
  ulwwds:any;
  constructor(public commonservice:CommonService,private sanitizer: DomSanitizer) { }

  ngOnInit() {
    window.scrollTo(0,0)
    this.get_data();
    this.imagesrc = this.commonservice.basepath;
  }

  get_data(){
    this.commonservice.get_whatwe_banner().subscribe((res:any)=>{
    this.showdata =  res.data;
    this.topBanner=this.imagesrc+"/bannerImage/"+"/"+this.showdata[0].image_folder_name+"/"+this.showdata[0].image
    console.log(this.topBanner)
  }) 
  this.commonservice.get_whatwedo().subscribe((res:any)=>{
   console.log(res.data)
    this.testData =  res.data;
    // this.topBanner=this.imagesrc+"/bannerImage/"+this.showdata[0].image_folder_name+"/"+this.showdata[0].image
    // this.topBanner=this.imagesrc+"/bannerImage/"+"/"+this.showda1ta[0].image_folder_name+"/"+this.showdata[0].image
    // console.log(this.topBanner)
    // this.topImg=this.imagesrc+"/whatwedo/"+"/"+this.testData[0].folder_name+"/"+this.testData[0].image
    // this.topSide=this.imagesrc+"/whatwedo_sidebar/"+"/"+this.testData[0].folder_name+"/"+this.testData[0].sidebar_image
    // console.log(this.topImg)
    // this.ulwwds=this.imagesrc+"/bannerImage/"+"/"+this.showdata[0].image_folder_name+"/"+this.showdata[0].image
  }) 
  }

}
