import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-offering',
  templateUrl: './offering.component.html',
  styleUrls: ['./offering.component.css']
})
export class OfferingComponent implements OnInit {

  constructor() { }

  buttonClickEvent(id){
    document.getElementById(id).scrollIntoView();
  };

  benifits = [
    {
      "name" : "Cloud",
      "id" : "_cloud",
      "items" : [
        {
          "name" : "Google Cloud",
          "description" : "Cloud #1",
          "click_url" : "#",
          "image_url" : "../../assets/images/logos/google-cloud.png",
          "detail":"Azure cloud credits worth up to $125,000, access to Microsoft Technology Centre, Founders Hub, MS Teams Community platform and enterprise network",
        },
        {
          "name" : "Amazon Web Services",
          "description" : "Cloud #2",
          "click_url" : "",
          "image_url" : "../../assets/images/logos/aws-logo.png",
          "cat_id":"1"
        }
      ]  
    },
    {
      "name" : "Development Tools",
      "id" : "_dbtool",
      "items" : [
        {
          "name" : "Mathworks",
          "description" : " Total credit of $33,700 for up to 10 licenses to meet software development requirements.",
          "click_url" : "",
          "image_url" : "../../assets/images/logos/fw-logo.png"
        }
      ]
    }
  ];
  selectedItem={};

  ngOnInit() {
  }

}
