import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ecosystem',
  templateUrl: './ecosystem.component.html',
  styleUrls: ['./ecosystem.component.css']
})
export class EcosystemComponent implements OnInit {


  curImage="";
  Image1="../../assets/images/benifits/InternalEcosystem.png";
  Image2="../../assets/images/benifits/External.jpg";
  ecosystem = "internal"
  removeClass: any;
  addClass: any;


  // imageSrc='';
  // messageText='';
  // imageButtons= [
  //   {src:'../../assets/images/benifits/InternalEcosystem.png',name:'internalEcosystem'},
  //   {src:'../../assets/images/benifits/ExternalEcosystem.png',name:'externalEcosystem'},
  // ];

  

  constructor() {   }
  
 
  ngOnInit() {
    this.curImage=this.Image1;
    this.removeClass="but_deactive";
    this.addClass="but_active";

    
  }
  
}
