import { Component, OnInit } from '@angular/core';
import {FormBuilder,FormGroup,FormControl,Validators,NgForm} from '@angular/forms'  
import { CommonService } from '../service/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.css']
})

export class UserLoginComponent implements OnInit {
  loginForm:FormGroup; 
  UserName:string="";  
  PassWord:string=""; 
  constructor(private frmbuilder:FormBuilder,private comm:CommonService,private router: Router) { 
    this.loginForm=frmbuilder.group({  
      uname:['',Validators.required],  
      upass:['',Validators.required] 
      });
  }

  ngOnInit() {

  }

  PostData(data)  
  {  
    console.log(data);
    var test = "1111";
    this.comm.postData(data.value).subscribe(
      res=>{
        console.log(res);
        if(res===null){
          console.log("invalid")
        }
        else{
          console.log("valid")
          console.log(res);
          localStorage.setItem("sinelogin","Ok");
          this.router.navigate(['/user-login-success']);
        }
        
      },
      error=>{
        console.log(error);
      }
    );
  } 

}
