import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-weeprogram',
  templateUrl: './weeprogram.component.html',
  styleUrls: ['./weeprogram.component.css']
})
export class WeeprogramComponent implements OnInit {
  eventkeys: any = [];eventdata: any = [];imagesrc: any;defaultindex=1;getid:any;
  constructor(public commonservice: CommonService, private route: ActivatedRoute,public router:Router) { }

  ngOnInit() {
    window.scrollTo(0,0)
    this.get_data()
    this.imagesrc = this.commonservice.basepath;
    this.getid = this.route.queryParams['value'].id;
    // console.log(this.getid.id)
    // if(localStorage.getItem('change route')){
    //   this.commonservice.get_homeEvents().subscribe((res:any)=>{
    //     this.eventkeys = res.data;
    //     this.eventdata = this.eventkeys.filter(val=>val.id==this.defaultindex);
    //   console.log("keys",this.eventkeys)
    //   console.log(localStorage.getItem('change route'));
    //   this.eventdata = this.eventdata.filter(val=>val.id==localStorage.getItem('change route'));
    //   this.defaultindex = this.eventdata[0].id
    // }) 
    // }
    // this.commonservice.namechanged.subscribe(res=>{
    //   console.log("res call from service",res)
    //   if(res){
    //     this.eventdata = this.eventkeys.filter(val=>val.event_heading==res);
    //     this.defaultindex = this.eventdata[0].id
    //   }
    // })


  }
  get_data() {
    this.commonservice.get_homeEvents().subscribe((res: any) => {
      console.log(res.data, typeof res.data)
      this.eventdata = res.data;
      console.log("events",this.eventdata)
      this.eventdata = res.data.filter(res=>res.id==this.getid)
      console.log("events",this.eventdata)

      // this.eventkeys = Object.keys(this.eventdata);
      // console.log("this.eventdata", this.eventdata);
    });
  }

  checkdata(keydata){
    console.log(keydata);
    this.defaultindex = keydata;
    this.eventdata = this.eventkeys.filter(val=>val.id==this.defaultindex);
    console.log("showdata",this.eventdata)
  }

}
