import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NguCarouselModule } from '@ngu/carousel';
import { GalleryModule } from 'ng-gallery';
import { AccordionModule } from "ngx-accordion";
import { NgHttpLoaderModule } from 'ng-http-loader';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Interceptor } from './interceptor';
import { FlickityModule } from 'ngx-flickity';
import { AuthGuard } from './guard/auth.guard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { AboutComponent } from './about/about.component';
import { TeamComponent } from './team/team.component';
import { BoardComponent } from './board/board.component';
import { GalleryComponent } from './gallery/gallery.component';
import { ProgramComponent } from './program/program.component';
import { ResourcesComponent } from './resources/resources.component';
import { ProgramPrayasComponent } from './program-prayas/program-prayas.component';
import { ProgramEirComponent } from './program-eir/program-eir.component';
import { ProgramAitComponent } from './program-ait/program-ait.component';
import { ProgramPluginComponent } from './program-plugin/program-plugin.component';
import { FaqComponent } from './faq/faq.component';
import { SineincubComponent } from './sineincub/sineincub.component';
import { BionestComponent } from './bionest/bionest.component';
import { WelcomepageComponent } from './welcomepage/welcomepage.component';
import { ContactComponent } from './contact/contact.component';
import { NewsComponent } from './news/news.component';
import { XfactorComponent } from './xfactor/xfactor.component';
import { DiscComponent } from './disc/disc.component';
import { WeeprogramComponent } from './weeprogram/weeprogram.component';
import { MeityloginComponent } from './meitylogin/meitylogin.component';
import { MediacoverageComponent } from './mediacoverage/mediacoverage.component';
import { TendorComponent } from './tendor/tendor.component';
import { TenderdetailsComponent } from './tenderdetails/tenderdetails.component';
import { ArchivetendersComponent } from './archivetenders/archivetenders.component';
import { CareerComponent } from './career/career.component';
import { AdvisorsComponent } from './advisors/advisors.component';
import { MentorsComponent } from './mentors/mentors.component';
import { FacilitiesComponent } from './facilities/facilities.component';
import { WhatwedoComponent } from './whatwedo/whatwedo.component';
import { SupportpartnersComponent } from './supportpartners/supportpartners.component';
import { OtherpartnersComponent } from './otherpartners/otherpartners.component';
import { FacilitesdetailsComponent } from './facilitesdetails/facilitesdetails.component';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { GalleryearComponent } from './galleryear/galleryear.component';
import { RegisterComponent } from './register/register.component';
import { UserLoginComponent } from './user-login/user-login.component';
import { UserLoginSuccessComponent } from './user-login-success/user-login-success.component';
import { ConnectusComponent } from './connectus/connectus.component';
import { SafepipePipe } from './safepipe.pipe';
import { ErrorComponent } from './error/error.component';
import { SlugifyPipe } from './slugify.pipe';
import { BenifitsineComponent } from './benifitsine/benifitsine.component';
import { PartnerOfferingComponent } from './partner-offering/partner-offering.component';
import { InfrastuctureComponent } from './infrastucture/infrastucture.component';
import { CapitalComponent } from './capital/capital.component';
import { EcosystemComponent } from './ecosystem/ecosystem.component';
import { InfraGalleryComponent } from './infra-gallery/infra-gallery.component';
import { OfferingComponent } from './offering/offering.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    PortfolioComponent,
    AboutComponent,
    TeamComponent,
    BoardComponent,
    GalleryComponent,
    ProgramComponent,
    ResourcesComponent,
    ProgramPrayasComponent,
    ProgramEirComponent,
    ProgramAitComponent,
    ProgramPluginComponent,
    FaqComponent,
    SineincubComponent,
    BionestComponent,
    WelcomepageComponent,
    ContactComponent,
    NewsComponent,
    XfactorComponent,
    DiscComponent,
    WeeprogramComponent,
    MeityloginComponent,
    MediacoverageComponent,
    TendorComponent,
    TenderdetailsComponent,
    ArchivetendersComponent,
    CareerComponent,
    AdvisorsComponent,
    MentorsComponent,
    FacilitiesComponent,
    WhatwedoComponent,
    SupportpartnersComponent,
    OtherpartnersComponent,
    FacilitesdetailsComponent,
    TestimonialComponent,
    GalleryearComponent,
    RegisterComponent,
    UserLoginComponent,
    UserLoginSuccessComponent,
    ConnectusComponent,
    SafepipePipe,
    ErrorComponent,
    SlugifyPipe,
    MentorsComponent,
    BenifitsineComponent,
    PartnerOfferingComponent,
    InfrastuctureComponent,
    CapitalComponent,
    EcosystemComponent,
    InfraGalleryComponent,
    OfferingComponent,
  ],
  imports: [
    // SafePipeModule,
    BrowserModule,
    AppRoutingModule,
    NguCarouselModule,
    BrowserAnimationsModule,
    AccordionModule,
    FlickityModule,
    FormsModule,
    ReactiveFormsModule,
    NgHttpLoaderModule,
    HttpClientModule,
    GalleryModule.forRoot({
      style: {
        height: '500px',
        width: '700px'
      }
    })
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true }, AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule {
}
