import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.css']
})
export class ResourcesComponent implements OnInit {
  showdata:any[];keys:any[];imagesrc:any;
  showdata2:any = [];
  image_folder_name:any;
  image:any;
  topBanner: string;

  constructor(public commonservice:CommonService,private http:HttpClient) { }

  ngOnInit() {
    window.scrollTo(0,0)
    this.get_data();
    this.get_data2();
    this.imagesrc= this.commonservice.basepath;
  }

  get_data(){
    this.commonservice.get_resources().subscribe((res:any)=>{
    this.showdata =  res.data;
    this.keys = Object.keys(this.showdata);
  }) 
}

get_data2(){
  this.commonservice.get_documents_banner().subscribe((res:any)=>{
  this.showdata2 =  res.data;
  this.image_folder_name = this.showdata2[0].image_folder_name;
  this.image = this.showdata2[0].image;
  this.topBanner=this.imagesrc+"/bannerImage/"+this.image_folder_name+"/"+this.image
}) 
}
  
}
