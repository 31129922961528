import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-facilities',
  templateUrl: './facilities.component.html',
  styleUrls: ['./facilities.component.css']
})
export class FacilitiesComponent implements OnInit {
  imagesrc: any; showdata2: any = []; facilityData: any = [];
  unitOne: boolean = false;
  unitNames: any = []; unitIds: any = []; headings: any = []; heads: any = [];
  allheading: any = []; singleUnit: any = []; uids: any = [];
  shpage: boolean = true;
  showdata3: any;
  searchUnitNames: any[];
  link: any
  image_folder_name:any =''
  bannerpath: any;
  imgpath: any;
  constructor(public commonservice: CommonService, private router: Router) { }

  ngOnInit() {
    this.get_data2();
    this.get_iitbdata();
    this.image_folder_name;
    this.imagesrc = this.commonservice.basepath;
    this.get_all_facilities();
  }

  get_data2() {
    this.commonservice.get_facilitiesBanner().subscribe((res: any) => {
      this.showdata2 = res.data;
      //this.bannerpath=this.showdata2[0].image_folder_name;
      this.imgpath=this.showdata2[0].image
      this.bannerpath= this.imagesrc+'/bannerImage/'+this.showdata2[0].image_folder_name+'/'+this.imgpath;
     
    })
  }

  get_iitbdata() {
    this.commonservice.get_iitbpage().subscribe((res: any) => {
      this.showdata3 = res.data;

    })
  }

  get_all_facilities() {
    this.commonservice.get_facilitiesPara().subscribe((res: any) => {
      this.link = res.data[0].link;
      
    });
    this.commonservice.get_facilities().subscribe((res: any) => {
      this.facilityData = res.data;
      
      for (let i = 0; i < this.facilityData.length; i++) {
        if (this.unitNames.length > 0) {
        
          var units = {
            id: this.facilityData[i].unit_id,
            name: this.facilityData[i].unit_name
          };
          var heads1 = {
            id: this.facilityData[i].id,
            name: this.facilityData[i].heading
          }
          this.unitNames.push(units);
          
          this.headings.push(heads1);
          
        } else {
          var units = {
            id: this.facilityData[i].unit_id,
            name: this.facilityData[i].unit_name
          }
          var heads1 = {
            id: this.facilityData[i].id,
            name: this.facilityData[i].heading
          }
          this.unitNames.push(units);
          this.headings.push(heads1);
        }
      }
      this.unitNames = Array.from(this.unitNames.reduce((m, t) => m.set(t.name, t), new Map()).values());
      this.allheading = this.headings;
    })
  }

  onChangeUnits(ev) {
    this.headings = [];
    var unitid = ev;
    var b = document.querySelector("#checkmark" + ev);
    if (!this.uids.find(x => x == ev)) {
      b.setAttribute("checked", "checked");
      this.uids.push(ev);
    }
    else {
      b.removeAttribute("checked");
      this.uids.splice(this.uids.indexOf(ev), 1);
    }


    this.commonservice.get_facilities().subscribe((res: any) => {
      this.unitOne = false;
      this.facilityData = res.data;
      if (this.uids.length > 0) {
        for (var j = 0; j < this.uids.length; j++) {
          for (let i = 0; i < this.facilityData.length; i++) {
            if (this.facilityData[i].unit_id == this.uids[j]) {
              var heads1 = {
                id: this.facilityData[i].id,
                name: this.facilityData[i].heading
              }
              this.headings.push(heads1);
            }
          }
        }
      } else {
        this.headings = this.allheading;
      }

    })
  }

  getUnitDetails(unit) {
    this.commonservice.get_facilities().subscribe((res: any) => {
      this.facilityData = res.data;
      for (let i = 0; i < this.facilityData.length; i++) {
        if (this.facilityData[i].id == unit) {
          this.unitOne = true;
          this.singleUnit.push(this.facilityData[i]);
        }

      }
    })
  }
  onChangePage(event) {
    var evalue = event.target.value;
    if (evalue == 'sine') {
      this.shpage = true;
    } else {
      this.shpage = false;
    }
  }
  // navigate(unit) {
  //   let x = {};
  //   this.router.navigate(['/facilitiesdetails'], { queryParams: x })
  // }
  navigate(e,val)
  { 
    console.log(val);
    // this.commonservice.get_facilities({id:val})
    this.router.navigate(['/facilitiesdetails']);
  }
}