import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-mntors',
  templateUrl: './mentors.component.html',
  styleUrls: ['./mentors.component.css']
})
export class MentorsComponent implements OnInit {
  showdata:any[];keys:any[];imagesrc:any;imagesrc1:any;showdata1:any = [];
  topBanner: string;

  constructor(public commonservice:CommonService,private http:HttpClient) { }

  ngOnInit() {
    window.scrollTo(0,0)
    this.get_data();
    this.get_data2();
    this.imagesrc = this.commonservice.basepath;
    this.imagesrc1 = this.commonservice.basepath;
  }

  get_data(){
    this.commonservice.get_mentors().subscribe((res:any)=>{
    this.showdata =  res.data;
    this.keys = Object.keys(this.showdata);
  }) 
}

get_data2(){
  this.commonservice.get_mentors_banner().subscribe((res:any)=>{
  this.showdata1 =  res.data;
  this.topBanner=this.imagesrc+"/bannerImage/"+this.showdata1[0].image_folder_name+"/"+this.showdata1[0].image
  //console.log("team-banner",this.showdata)
}) 
}


}
