import { Component, OnInit } from '@angular/core';
import * as tabs from 'tabs';
import { CommonService } from '../service/common.service';
import * as _ from 'lodash';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {
  tabpcount:number=1;tabchildcount:number=0;showdata:any;keys:any=[];tabdata:any=[];
  tagName:any="current";
  maintabdata:any[];maindatakeys:any[];
  newData=[];
  branch:any=[];
  newTabData=[];
  filterData=[]
  selectedTabId:any =1 ;
imagesrc:any;imagesrc1:any;
showdata2:any = [];
tabselected:boolean=true;
  topBanner: any;
  selectedDesc:any;
  selectedTitle:any;
  constructor(public commonservice:CommonService) { }

  ngOnInit() {
    window.scrollTo(0,0)
    this.get_portfoliomainlist();
    this.get_portfoliotabs();
    this.get_data2();
    this.imagesrc= this.commonservice.basepath;
    this.get_data();
    // this.getTabs();  
    
  }

  get_portfoliomainlist(){
    this.commonservice.get_portfoliomaintabs().subscribe((res:any)=>{
      this.maintabdata = res.data;
      this.maindatakeys = Object.keys(this.maintabdata);
  }) 
}

  getTabs(){
    var container=document.querySelector('.tab-container');
    console.log(container);
    tabs(container);
    
    }

    get_portfoliotabs(){
          this.commonservice.get_portfolioTabs().subscribe((res:any)=>{
          this.tabdata =res.data;
          this.newTabData=[];
          this.newTabData.splice(0,0,{id:"0",port_domain_name:"All"})
          for(var i=0;i<this.tabdata.length;i++)
          {
            if(this.tabdata[i].type_id == 1)
            {
              this.newTabData.push(this.tabdata[i])
            }
          } 
          
        }) 
      }


        get_data(){
      this.commonservice.get_PortfolioCompanyTab().subscribe((res:any)=>{
      this.showdata =  _.groupBy( res.data, function(b) { return b.port_comp_type_id});
      this.newData = this.showdata[this.selectedTabId];
      this.branch =this.newData;
    }) 
  }


    tabparent(tabid){
      this.tabchildcount =0;
      this.selectedTabId =tabid
      this.newTabData=[];
      this.newTabData.splice(0,0,{id:"0",port_domain_name:"All"})
      for(var i=0;i<this.tabdata.length;i++)
      {
        if(tabid == this.tabdata[i].type_id)
        {
          this.newTabData.push(this.tabdata[i])
        }
      } 
      this.branch = this.showdata[this.selectedTabId]
      
        
    }

    tabchild(child){
      this.branch=[];
      this.tabchildcount=child;
      this.newData = this.showdata[this.selectedTabId];
      if(child > 0)
      {
        this.branch = this.newData.filter(function(e) {
        return e.port_domain_id == child;
      });
      this.tabselected = false;
      }
      else{
        this.branch =this.newData;
        this.tabselected = true;
      }
    }
    get_data2(){
      this.commonservice.get_portfolio_banner().subscribe((res:any)=>{
      this.showdata2 =  res.data;
      this.topBanner=this.imagesrc+"/bannerImage/"+this.showdata2[0].image_folder_name+"/"+this.showdata2[0].image;
    }) 
    }
  
}

