import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.css']
})
export class CareerComponent implements OnInit {
  showdata:any;keys:any[];showdata1:any;keys1:any[];imagesrc:any;
  constructor(public commonservice:CommonService,private http:HttpClient) { }

  ngOnInit() {
    window.scrollTo(0,0)
    this.get_data();
    this.get_imagedata();
    this.imagesrc= this.commonservice.basepath;
  }

  get_data(){
    this.commonservice.get_careerslisting().subscribe((res:any)=>{
      if(res.data){
        this.showdata = res.data;
      }
      else{
        this.showdata = '';
      }
    
    
    this.keys = Object.keys(this.showdata);
    
  })
}

get_imagedata(){
  this.commonservice.get_careerimage().subscribe((res:any)=>{
    if(res.data){
      this.showdata1 = res.data;
    }
    else{
      this.showdata1 = '';
    }
  
  
  this.keys1 = Object.keys(this.showdata1);
})
}

}
