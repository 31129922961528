import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tenderdetails',
  templateUrl: './tenderdetails.component.html',
  styleUrls: ['./tenderdetails.component.css']
})
export class TenderdetailsComponent implements OnInit {
  showdata:any={};keys:any[];filedata:any={};imagesrc:any;
  constructor(public commonservice:CommonService,private http:HttpClient,public route : ActivatedRoute) { }

  ngOnInit() {
    this.get_data(this.route.snapshot.paramMap.get("id"));
    this.get_data1(this.route.snapshot.paramMap.get("id"));
    this.imagesrc= this.commonservice.basepath;
    
  }

  get_data(id){
    console.log(id)
    this.commonservice.get_tender(id).subscribe((res:any)=>{
      console.log(res)
      this.showdata =  res;
    // this.showdata = this.keys.filter(val=>val.tender_id==this.defaultindex);
    console.log("tendorlist",this.showdata)
  }) 
}

get_data1(id){
  console.log(id)
  this.commonservice.get_tender1(id).subscribe((res:any)=>{
    console.log(res)
    this.filedata =  res;
  // this.showdata = this.keys.filter(val=>val.tender_id==this.defaultindex);
  console.log("tendorlist",this.filedata)
}) 
}

// checkdata(keydata){
//   console.log(keydata);
//   this.defaultindex = keydata;
//   this.showdata = this.keys.filter(val=>val.tender_id==this.defaultindex);
//   console.log("showdata",this.showdata)
// }
}
