import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {
  showdata: any = [];
  showdata1: any = [];
  keys: any = [];
  imagesrc: any;imagesrc1: any;
  topBanner: string;
  constructor(public commonservice: CommonService, private http: HttpClient) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.get_data();
    this.get_data2();
    this.imagesrc = this.commonservice.basepath;
    this.imagesrc1 = this.commonservice.basepath;
  }

  get_data() {
    this.commonservice.get_teamdetails().subscribe((res: any) => {
      var data = _.groupBy(res.data, 'row_number');
      for (var i = 0; i < Object.keys(data).length; i++) {
        var arr = _.sortBy(data[Object.keys(data)[i]], ['rank']).sort(function (a, b) {
          return a.rank - b.rank
        });
        this.showdata.push(arr);
      }
      
    })
  }

  get_data1() {
      this.commonservice.get_teamdetails().subscribe((res: any) => {
      this.showdata = res.data;
      this.keys = Object.keys(this.showdata);
      var equal = []
      for (var k = 0; k < this.showdata.length; k++) {
        equal.push(this.showdata[k].designation)
      }
      let uniques = Array.from(new Set(equal));
      var test = [];
      var demo = [];
      for (var m = 0; m < uniques.length; m++) {
        demo = this.showdata.filter((res) => { return res.designation == uniques[m] })
        test.push(demo)
      }
      this.showdata = test;
      
      for (var j = 0; j < this.showdata.length - 1; j++) {
        for (var m = 0; m < this.showdata[j + 1].length; m++) {
          if (this.showdata[j].length > 0) {
            if (this.showdata[j][0].designation == this.showdata[j + 1][m].in_row) {
              this.showdata[j].push(this.showdata[j + 1][m])
              this.showdata[j + 1].splice(m, 1)
            }
          }
        }
      }
    })
  }

  get_data2(){
    this.commonservice.get_team_banner().subscribe((res:any)=>{
    this.showdata1 =  res.data;
    this.topBanner=this.imagesrc1+"/bannerImage/"+this.showdata1[0].image_folder_name+"/"+this.showdata1[0].image
    
  }) 
  }

}