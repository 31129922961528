import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../service/common.service';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import * as moment from 'moment';
@Component({
  selector: 'app-tendor',
  templateUrl: './tendor.component.html',
  styleUrls: ['./tendor.component.css']
})
export class TendorComponent implements OnInit {
  showdata:any;keys:any[];
  constructor(public commonservice:CommonService,private http:HttpClient,public router:Router) { }

  ngOnInit() {
    window.scrollTo(0,0)
    this.get_data();
    // this.addClass();
  }

  get_data(){
    this.commonservice.get_tendorlist().subscribe((res:any)=>{
      if(res.data){
        this.showdata = res.data;
      }
      else{
        this.showdata = '';
      }
    this.showdata.map( i => { 
      return i.due_date = moment(i.due_date).format("DD-MMMM-YYYY");
    })
    this.keys = Object.keys(this.showdata);
    
  })
}
getchange(menu){
  this.router.navigateByUrl('/tenderdetails');
  localStorage.setItem("change route",menu)
}

}
