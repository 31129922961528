import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-login-success',
  templateUrl: './user-login-success.component.html',
  styleUrls: ['./user-login-success.component.css']
})
export class UserLoginSuccessComponent implements OnInit {
  public companyData:any= [];
  public userData:any= [];

  constructor(private comm:CommonService,public router:Router) { 
    
  }

  ngOnInit() {

    if(!localStorage.getItem("sinelogin"))
    {
      this.router.navigate(['/home']);
    }
    this.get_CompanyData();
    this.get_UserData();

  }

  get_CompanyData(){
    this.comm.get_usercom_data().subscribe((res)=>{
      this.companyData=res['data'];
    })
  }
  get_UserData(){
    this.comm.get_user_data().subscribe((res)=>{
      this.userData=res['data'];
      // console.log(this.userData);
    })
  }

}
