import { Component, OnInit, HostListener } from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "../service/common.service";
import { HttpClient } from "@angular/common/http";
import * as _ from "lodash";
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"]
})
export class FooterComponent implements OnInit {
  showdata: any[];
  keys: any[];
  keys1: any[];
  contactdata;
  subscribeForm: FormGroup;
  msg: any;

  constructor(public commonservice: CommonService, public router: Router,private fb:FormBuilder,) {
    this.subscribeForm=this.fb.group({
      useremail:['',[Validators.required,Validators.email]]
    })


  }

  @HostListener("window:scroll", []) onWindowScroll() {
    this.scrollFunction();
  }
  subscribe()
  {
    
    //console.log(this.subscribeForm.value.useremail);
    this.commonservice.post_subscriber({"subscribe_email":this.subscribeForm.value.useremail}).subscribe((res)=>{
      //console.log(res);
      this.msg="You subscribe successfully.";
      setTimeout(() => {
        this.msg="";
        },2000);  //5s
        this.subscribeForm.reset();
    },
    (err)=>{
      console.log(err);
    })
  }
  // When the user scrolls down 20px from the top of the document, show the button
  scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document.getElementById("myBtn").style.display = "block";
    } else {
      document.getElementById("myBtn").style.display = "none";
    }
  }

  onEdit() {
    window.scrollTo(0, 0);
  }
  ngOnInit() {
    // console.log(this.router.routerState.snapshot.url)
    this.get_data();
    this.get_contactdata();
  }

  get_data() {
    this.commonservice.get_footer().subscribe((res: any) => {
      this.showdata = res.data;
      // console.log("footer",this.showdata)
      this.keys = this.showdata.splice(0, 9);
      // console.log("keysfoot",this.keys)
      this.keys1 = this.showdata;
      // console.log("keys1",this.keys1)
    });
  }

  get_contactdata() {
    this.commonservice.get_contact().subscribe((res: any) => {
      // console.log(res);
      this.contactdata = res.data;
      // console.log(this.contactdata)
    });
  }
}
