import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-infrastucture',
  templateUrl: './infrastucture.component.html',
  styleUrls: ['./infrastucture.component.css']
})
export class InfrastuctureComponent implements OnInit {

  constructor() { }
 

  // showdata = [
  //   { imagesrc: '../../assets/images/logos/aws-logo.png', alt: 'Image 1' },
  //   { imagesrc: '../../assets/images/logos/aws-logo.png', alt: 'Image 2' },
  //   { imagesrc: '../../assets/images/logos/aws-logo.png', alt: 'Image 3' },
  //   { imagesrc: '../../assets/images/logos/aws-logo.png', alt: 'Image 4' },

  //   // etc.
  // ];

  ngOnInit() {
  }

}
