import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-welcomepage',
  templateUrl: './welcomepage.component.html',
  styleUrls: ['./welcomepage.component.css']
})
export class WelcomepageComponent implements OnInit {
  constructor(private router:Router) { }

  ngOnInit() {
    setTimeout(() => {
      // window.location.href = "http://www.cloudesigntechnologies.com/apps/iit/sine/v5/home";
      // window.location.href = "/home";
      // this.router.navigateByUrl('/home');
    }, 1000);
  }
   
}

