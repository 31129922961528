import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-sineincub',
  templateUrl: './sineincub.component.html',
  styleUrls: ['./sineincub.component.css']
})
export class SineincubComponent implements OnInit {
 
  showdata:any=[];
  keys:any=[];
  imagesrc:any;
  defaultindex:number;
  type:any;
  indexData:any=[];
  programeName:any;
  constructor(public commonservice:CommonService,private http:HttpClient,private router: Router,private route: ActivatedRoute) { }
  
  ngOnInit() {
    window.scrollTo(0,0)
    this.get_data();
    this.imagesrc= this.commonservice.basepath;
    if(localStorage.getItem('change route')){
      this.commonservice.get_prodetails().subscribe((res:any)=>{
        // location.reload();
        this.keys = res.data;
        this.showdata = this.keys.filter(val=>val.programe_id==this.type.toUpperCase().replace(/-/g, " "));
        localStorage.setItem("change route", this.type.toUpperCase().replace(/-/g, " "));
      console.log("keys",this.keys)
      console.log(localStorage.getItem('change route'));
      this.showdata = this.keys.filter(val=>val.programe_name.toUpperCase()==localStorage.getItem('change route').toUpperCase());
      this.defaultindex = this.showdata[0].programe_id
    }) 
    }
    this.commonservice.namechanged.subscribe(res=>{
      console.log("res call from service",res)
      if(res){
        this.showdata = this.keys.filter(val=>val.programe_name.toUpperCase()==res.toUpperCase());
        this.defaultindex = this.showdata[0].programe_id
      }
    })
  }

  get_data(){
    this.type = this.route.snapshot.params.type;
    console.log("this.type",this.type)
    console.log("this.nameupper",this.type.toUpperCase().replace(/-/g, " "))
    this.commonservice.get_prodetails().subscribe((res:any)=>{
      this.keys = res.data;
      console.log("index............",this.defaultindex)
      // this.showdata = this.keys.filter(val=>val.programe_id==this.defaultindex);
      console.log("keys",this.keys)
      this.indexData =  this.keys.filter(val=>val.programe_name==this.type.toUpperCase().replace(/-/g, " "));
      console.log("defaultindex",this.indexData)
      this.indexData.forEach(element => {
        this.defaultindex = element.programe_id
      });
      this.showdata =  this.indexData 
      console.log("defaultindex............",this.defaultindex)
  }) 
}

checkdata(keydata,keyname) {
  console.log(keydata);
  this.defaultindex = keydata;
  if(this.keys[0].programe_name && keyname) {
    this.router.navigateByUrl('/programmes'+'/'+ keyname.replace(/\s+/g, "-").toLowerCase());
  }
  this.showdata = this.keys.filter(val=>val.programe_id==this.defaultindex);
  console.log("showdata",this.showdata)
}



}
