import { Component, OnInit, ViewChild, HostListener, ElementRef, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../service/common.service';
import * as _ from 'lodash';
import { longStackSupport } from 'q';
// import { EventEmitter } from 'events';
// import { SlugifyPipe } from '.././slugify.pipe';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {


	@ViewChild('myDiv') myDiv: ElementRef;
	@ViewChild('fixed') el: ElementRef; fix: boolean; scrolllink: string;
	@Output() scrollEvent1 = new EventEmitter();
	navbarOpen = false;
	navbarOpen1 = false;
	programOpen: boolean;
	subMenu = false; aboutOpen: boolean = false; networkOpen: boolean;
	menus: any = {};
	hidee: any;
	flagclose = 1;
	disableAbout: any = '';
	showdata: any = []; keys: any = []; showsubdata: any = []; showsubkeys: any[]; showdatedata: any = []; showdatekeys: any[];
	showBlinker: any = [];
	previousName: any;
	blink: boolean = false;
	@HostListener('window:scroll', ['$event']) onScrollEvent($event) {
		if (window.outerWidth >= 768) {
			if (window.pageYOffset >= 480) {
				this.fix = true;
			} else {
				this.fix = false;
			}
		}

		var scrollpos = window.pageYOffset;
		var docheight = document.body.clientHeight;
		var winheight = window.outerHeight;
		var scrolled = (scrollpos / (docheight - winheight)) * 100;
		if (scrollpos > 480 && scrolled < 100) {
			document.getElementsByClassName("full-width-header")[0].classList.add("facility-fixed");
		}
		else {
			document.getElementsByClassName("full-width-header")[0].classList.remove("facility-fixed");
		}
	}

	constructor(public commonservice: CommonService, private router: Router) { this.router.events.subscribe(event => this.modifyHeader(event)); }

	modifyHeader(location) {
		if (location.url == '/' && location.url != undefined) {
			document.getElementById('home-a').classList.add('home-a-css')
			
		}
		if (location.url !== '/' && location.url !== undefined) {
			document.getElementById('home-a').classList.remove('home-a-css')
			
		}
	}

	toggleNavbar() {
		this.navbarOpen = !this.navbarOpen
	}

	toggleNavbar1(name) {
		if (window.innerWidth <= 768) {
			this.disableAbout = name;
			this.flagclose = 1;
			
			if ((this.disableAbout == this.previousName) && this.flagclose == 0) {
				this.navbarOpen1 = !this.navbarOpen1;
				this.flagclose = 1;
			}
			else {
				this.navbarOpen1 = true;
				this.previousName = name;
				this.flagclose = 0;
			}
		}
		else {
			this.disableAbout = name
		}
	}
	hide() {
		if (window.innerWidth <= 768) {
			setTimeout(() => {
				this.hidee = true;
				this.disableAbout = '';
			}, 2000);
		}
	}
	navhide(name) {
		this.previousName = name;
		this.disableAbout = name
	}

	getchange(menu) {
		// let subMenu = menu.replace(/\s+/g, "");

    	// el.click();
		
		let subMenu = menu.replace(/\s+/g, "-").toLowerCase();
		this.router.navigateByUrl('/programmes'+'/'+ subMenu);
		localStorage.setItem("change route", menu)
		this.commonservice.change_name(menu)
		// this.myDiv.nativeElement.click();
		let el: HTMLElement = document.querySelector('#myDiv') as HTMLElement;
		// console.log(el)
		el.click();
		// alert(el)
		// console.log()
		// document.getElementById("rs-menu-toggle").click()
		// element.click();
		// window.location.reload();
		// 
	}
	getPartners(menu) {
		let subMenu = menu.replace(/\s+/g, "-").toLowerCase();
		this.router.navigateByUrl('/partners'+'/'+ subMenu);
		localStorage.setItem("change route", menu)
		this.commonservice.change_name(menu)
	}
	/*m-functions*/
	infunc() {
		document.getElementById("rel-pos").style.zIndex = "0";
	}
	infunc1() {
		document.getElementById("rel-pos").style.zIndex = "1";
	}
	onProgramClick(name) {
		if (window.innerWidth <= 991) {
			if (name == 'about') {
				this.aboutOpen = !this.aboutOpen;
				if (this.aboutOpen) {
					document.getElementById("about").style.display = "block";
				}
				else {
					document.getElementById("about").style.display = "none";
				}
			}
			if (name == "program") {
				this.programOpen = !this.programOpen;
				if (this.programOpen) {
					document.getElementById("program").style.display = "block";
				}
				else {
					document.getElementById("program").style.display = "none";
				}
			}
			if (name == "network") {
				this.networkOpen = !this.networkOpen;
				if (this.networkOpen) {
					document.getElementById("network").style.display = "block";
				}
				else {
					document.getElementById("network").style.display = "none";
				}
			}
		}
	}

	ngOnInit() {
		this.get_data();
		this.get_data1();
		this.get_blinker();
	}

	navigationSubMenu(i) {
		const childreArray = document.getElementById("home-a").children[0].children
		const childrenLength = document.getElementById("home-a").children[0].children.length;
		const activeSubMenu = document.getElementById("home-a").children[0].children[i];
		
		// for (let j = 0; j < childrenLength; j++) {
		// 	childreArray[j].classList.remove('open-submenu')
		// }
		activeSubMenu.classList.toggle("open-submenu");
	}

	test(key) {
		localStorage.setItem('tab', key)
		this.router.navigate(['/home'])
	}
	scroll(el) {
		window.scrollTo(0, el.offsetTop - 150);
		
	}

	scrollThis(key) {
		this.scrollEvent1.emit(key)
	}
	mouseEnter() {
		document.getElementsByClassName("announcement-wrap")[0].classList.add("z-index");
	}
	mouseLeave() {
		document.getElementsByClassName("announcement-wrap")[0].classList.remove("z-index")
	}

	get_data() {
		this.commonservice.get_menu().subscribe((res: any) => {
			this.showdata = res;
			this.keys = Object.keys(this.showdata);
		})
	}
	get_blinker() {
		this.commonservice.get_blinker().subscribe((res: any) => {
			 
			if(res!==null || res!==undefined || res!==''){
				this.showBlinker = res;
				
			}
			else{
				
			}
			
		})
	}

	get_data1() {
		this.commonservice.get_tendorlist().subscribe((res: any) => {
			if (res.rc) {
				this.showdatedata = res.data;
				var test = res.data.sort(function (a, b) {
					var c: any = new Date(b.created_on);
					var d: any = new Date(a.created_on);
					return c - d;
				})[0];
				var today = new Date();
				var yesterday = new Date(new Date().getTime() - (24 * 60 * 60 * 1000))
				if (new Date(test.due_date) > yesterday) this.blink = true;
				if (this.blink) {
					document.getElementById('tendorslistdiv').style.display = "block";
				}
				this.showdatekeys = Object.keys(this.showdatedata);
			}
		})
	}

}
